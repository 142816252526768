import Standings from './Components/Standings';
import './App.css';

function App() {
  return (
    <>
    <Standings />
    </>
  );
}

export default App;
